<template>
  <validation-provider
    v-slot="validationContext"
    ref="userRoles"
    :rules="isRoleRequired ? 'required' : ''"
    vid="user_roles"
    name="User Roles"
  >
    <b-form-group
      :label="label"
      label-for="user-role"
      class="mb-0"
    >
      <v-select
        id="user-role"
        v-model="selectedUserRole"
        label="name"
        :multiple="multipleRolesEnabled"
        :placeholder="placeholder"
        :options="userRolesSet"
        :selectable="(option) => disabledRole(option.id)"
        :state="
          getValidationState(
            validationContext
          )
        "
        :style="readOnly ? 'pointer-events: none' : ''"
      />
      <b-form-invalid-feedback
        :state="
          getValidationState(
            validationContext
          )
        "
      >
        {{
          validationContext.errors[0]
        }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
<script>
import vSelect from "vue-select";
import { userRolesDisplay, userRoles } from '@models/userRoles';
import {  
  BFormGroup,
  BFormInvalidFeedback
} from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";
import {
  getValidationState
} from "@/libs/utils";
import _isEqual from 'lodash/isEqual';
import { mapGetters } from 'vuex';
import { roleAlias } from "@/@aom-core/utils/utils";
const userAllRolesSelect = [
  { id:userRoles.ADMIN, name:userRolesDisplay[userRoles.ADMIN] },
  { id:userRoles.CHAMPION, name:userRolesDisplay[userRoles.CHAMPION] },
  { id:userRoles.MENTOR, name:userRolesDisplay[userRoles.MENTOR] },
  { id:userRoles.MENTEE, name:userRolesDisplay[userRoles.MENTEE] }
];

const userLibraryRolesSelect = [
  { id:userRoles.MENTOR, name: userRolesDisplay[userRoles.MENTOR] },
  { id:userRoles.MENTEE, name:userRolesDisplay[userRoles.MENTEE] },
  { id:userRoles.TRAINEE, name:userRolesDisplay[userRoles.TRAINEE] }
];

const userPartRolesSelect = [
  { id:userRoles.MENTOR, name: userRolesDisplay[userRoles.MENTOR] },
  { id:userRoles.MENTEE, name:userRolesDisplay[userRoles.MENTEE] },
];
const userPartTraineeRolesSelect = [
  { id:userRoles.MENTOR, name: userRolesDisplay[userRoles.MENTOR] },
  { id:userRoles.MENTEE, name:userRolesDisplay[userRoles.MENTEE] },
  { id:userRoles.TRAINEE, name:userRolesDisplay[userRoles.TRAINEE] },
];
const userTraineeRolesSelect = [
  { id:userRoles.TRAINEE, name:userRolesDisplay[userRoles.TRAINEE] }
];

const userChampRolesSelect = [
  { id:userRoles.CHAMPION, name:userRolesDisplay[userRoles.CHAMPION] },
];

const userNoAdminRolesSelect = [
  { id:userRoles.CHAMPION, name:userRolesDisplay[userRoles.CHAMPION] },
  { id:userRoles.MENTOR, name:userRolesDisplay[userRoles.MENTOR] },
  { id:userRoles.MENTEE, name:userRolesDisplay[userRoles.MENTEE] }
];

export default {
  components: {
    vSelect,
    BFormGroup,
    ValidationProvider,
    BFormInvalidFeedback
  },
  props:{
    isRequired: {
      type: Boolean,
      default: true
    },
    listType: {
      type: String,
      default:'all'
    },
    value: {
      type: Array,
      default: () => []
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    formId: {
      type: Number,
      default: 0
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    multipleRolesEnabled: {
      type: Boolean,
      default: true
    },
    customLabel: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Select the User Roles'
    }
  },
  data() {
    return {
      selectedUserRole: this.value
    };
  },
  computed: {
    isRoleRequired() {
      return this.isRequired;
    },
    ...mapGetters('programs',['defaultProgram']),
    userRolesSet() {
      let options = userAllRolesSelect;
      switch (this.listType) {
      case "participant":
        options = userPartRolesSelect;
        break;
      case "library":
        options = userLibraryRolesSelect;
        break;
      case "trainee":
        options = userTraineeRolesSelect;
        break;
      case "champion":
        options = userChampRolesSelect;
        break;
      case "noAdmin":
        options = userNoAdminRolesSelect;
        break;
      default:
        break;
      }
      if (this.defaultProgram) {
        options = options.map(r => {
          return {
            ...r,
            name: roleAlias(r.id, this.defaultProgram)
          };
        });
      };
      return options;
    },
    label() {
      if (this.hideLabel) {
        return '';
      } else if (!this.hideLabel && this.customLabel) {
        return this.customLabel;
      }
      return "User Role";
    }
  },
  watch:{
    value () {
      this.selectedUserRole = Array.isArray(this.value) ? this.value: [this.value];
      if (this.defaultProgram) {
        this.selectedUserRole = this.selectedUserRole.map(r => {
          return {...r, name: roleAlias(r.id, this.defaultProgram)};
        });
      }
    },
    selectedUserRole(n, o){
      if(_isEqual(n, o)) {
        return;
      }
      this.$emit('update', Array.isArray(this.selectedUserRole)? this.selectedUserRole: (this.selectedUserRole? [this.selectedUserRole]: []));
    }
  },
  created() {
    if (this.defaultProgram) {
      if (!Array.isArray(this.selectedUserRole)) {
        this.selectedUserRole = [this.selectedUserRole];
      }
      this.selectedUserRole = this.selectedUserRole.map(r => {
        return {...r, name: roleAlias(r.id, this.defaultProgram)};
      });
    }
  },
  methods: {
    disabledRole(option){
      if(this.formId === 0) {
        return true;
      }
      if(this.formId !== 0 && this.value.filter(v => v.id === userRoles.MENTEE).length > 0 && option === userRoles.MENTEE) {
        return true;
      }
      if(this.formId !== 0 && this.value.filter(v => v.id === userRoles.MENTOR).length > 0 && option === userRoles.MENTOR) {
        return true;
      }
      return false;
    },
  },
  setup() {
    return {
      getValidationState
    };
  }
};
</script>
 